import Head from 'next/head';

const HeadTitle = () => {
  return (
    <Head>
      <link rel="icon" href="/images/favicon.png" />
    </Head>
  );
};

export default HeadTitle;
